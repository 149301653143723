<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table>
        <h1>{{ name }}</h1>
      </template>
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Menu</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <h2 class="section-title">Forms</h2>
      <p class="section-lead">
        Examples and usage guidelines for form control styles, layout options,
        and custom components for creating a wide variety of forms.
      </p>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="submit">
              <div class="card-body">
                <div class="form-group">
                  <label>Item</label>
                  <select class="custom-select" v-model="form.item_id">
                    <option selected="" value="">Open this select menu</option>
                    <option
                      v-for="item in data.items"
                      :key="item.value"
                      :value="item.value"
                      >{{ item.text }}</option
                    >
                  </select>
                  <small class="text-danger" v-if="errors.item_id">{{
                    errors.item_id[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Product</label>
                  <select class="custom-select" v-model="form.product_id">
                    <option selected="" value="">Open this select menu</option>
                    <option
                      v-for="product in data.products"
                      :key="product.value"
                      :value="product.value"
                      >{{ product.text }}</option
                    >
                  </select>
                  <small class="text-danger" v-if="errors.product_id">{{
                    errors.product_id[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Unit</label>
                  <select class="custom-select" v-model="form.unit_id">
                    <option selected="" value="">Open this select menu</option>
                    <option
                      v-for="unit in data.units"
                      :key="unit.value"
                      :value="unit.value"
                      >{{ unit.text }}</option
                    >
                  </select>
                  <small class="text-danger" v-if="errors.unit_id">{{
                    errors.unit_id[0]
                  }}</small>
                </div>
                <text-input :value.sync="form.qty" label="Value"></text-input>
              </div>
              <div class="card-footer text-right">
                <button class="btn btn-primary mr-1" type="submit">
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {
  ItemRepository,
  ProductRepository,
  SubitemRepository,
  UnitRepository,
} from "../../repositories/RepositoryFactory";
export default {
  name: "SubitemCreate",
  data() {
    return {
      name: "Sub item Create",
      form: {
        item_id: "",
        product_id: "",
        unit_id: "",
        qty: 0,
      },
      data: {
        items: [],
        products: [],
        units: [],
      },
      errors: [],
    };
  },
  mounted() {
    this.getItems();
    this.getProducts();
    this.getUnits();
  },
  methods: {
    getItems() {
      ItemRepository.getForSelect().then(({ data }) => {
        this.data.items = data.data;
      });
    },
    getProducts() {
      ProductRepository.getMinimal(true).then(({ data }) => {
        this.data.products = data.data;
      });
    },
    getUnits() {
      UnitRepository.getForSelect().then(({ data }) => {
        this.data.units = data.data;
      });
    },
    submit() {
      let formData = new FormData();
      Object.entries(this.form).forEach((e) => {
        if (e[1] != null) {
          formData.set(e[0], e[1]);
        }
      });
      SubitemRepository.create(formData)
        .then(({ data }) => {
          this.$noty.success("Successful adding product");
          this.$router.push({ name: "Subitem" });
        })
        .catch((err) => {
          this.errors = err.response.data;
        });
    },
  },
};
</script>
